<template>
  <v-dialog
    v-model="dialogVersionApp"
    @click:outside="$emit('update:dialogVersionApp', false)"
    @keydown.esc="$emit('update:dialogVersionApp', false)"
    max-width="500px"
  >
    <v-card :loading="loading">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-icon @click="$emit('update:dialogVersionApp', false)"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="validForm" lazy-validation>
          <v-row>
            <v-col cols="12" md="6"
              ><v-text-field
                label="Andriod"
                required
                v-model="versionApp.android"
                :rules="formRules"
              ></v-text-field
            ></v-col>
            <v-col cols="12" md="6"
              ><v-text-field
                label="Ios"
                required
                v-model="versionApp.ios"
                :rules="formRules"
              ></v-text-field
            ></v-col>
            <v-col cols="12" md="6"
              ><v-text-field
                label="Andriod Futuro"
                v-model="versionApp.android_futuro"
              ></v-text-field
            ></v-col>
            <v-col cols="12" md="6"
              ><v-text-field
                label="Ios Futuro"
                v-model="versionApp.ios_futuro"
              ></v-text-field
            ></v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!validForm"
          :loading="loading"
          depressed
          @click="
            isEmpty(versionAppOriginal) ? createVersion() : updateVersion()
          "
        >
          {{ isEmpty(versionAppOriginal) ? "Criar" : "Salvar" }}

          <v-icon v-if="isEmpty(versionAppOriginal) == true"
            >mdi-content-save-cog</v-icon
          >

          <v-icon v-else>mdi-content-save-check-outline</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { inputRequired } from "@/plugins/utils.js";
import {
  postVersionApp,
  putVersionApp,
  fetchVersoes,
} from "@/api/admin/version_app.js";

export default {
  name: "DialogVersion",

  props: {
    dialogVersionApp: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      validForm: true,
      loading: true,
      versionApp: {},
      versionAppOriginal: {},
      formRules: [inputRequired],
    };
  },

  computed: {},

  methods: {
    isEmpty(obj) {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    },

    createVersion() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        const versionApp = new FormData();

        let versionApp_2 = {};

        versionApp_2.android = this.versionApp.android;
        versionApp_2.ios = this.versionApp.ios;
        versionApp_2.android_futuro = this.versionApp.android_futuro;
        versionApp_2.ios_futuro = this.versionApp.ios_futuro;

        for (let key in versionApp_2) {
          if (
            versionApp_2[key] !== null &&
            versionApp_2[key] !== undefined &&
            versionApp_2[key] !== ""
          ) {
            versionApp.append(key, versionApp_2[key]);
          }
        }

        postVersionApp(versionApp)
          .then((response) => {
            if (response.status === 201) {
              this.$toast.success("Criado com sucesso");
              this.loading = false;
              this.$emit("update:dialogVersionApp", false);
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },

    updateVersion() {
      if (
        JSON.stringify(this.versionApp) ===
        JSON.stringify(this.versionAppOriginal)
      ) {
        this.$toast.error("Por favor, faça uma mudança antes de salvar!");
        return;
      }

      if (this.$refs.form.validate()) {
        this.loading = true;

        let versionApp_2 = {};

        versionApp_2.android = this.versionApp.android;
        versionApp_2.ios = this.versionApp.ios;
        versionApp_2.android_futuro = this.versionApp.android_futuro;
        versionApp_2.ios_futuro = this.versionApp.ios_futuro;

        putVersionApp(this.versionApp.id, versionApp_2)
          .then((response) => {
            if (response.status === 200) {
              this.$toast.success("Atualizado com sucesso");
              this.loading = false;
              this.$emit("update:dialogVersionApp", false);
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },

    getVersion() {
      this.loading = true;
      return fetchVersoes()
        .then((response) => {
          this.versionApp = { ...response };
          this.versionAppOriginal = { ...response };
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  async mounted() {
    await this.getVersion();
  },
};
</script>

<style></style>
