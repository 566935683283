import api from "../axios_service.js";

export async function fetchVersoes(filtros) {
  const response = await api.get(`/admin/version-app${filtros ? filtros : ""}`);
  return response.data.result;
}

export function postVersionApp(version) {
  return api.post("/admin/version-app/add", version);
}

export function putVersionApp( id ,version) {

  return api.put(`/admin/version-app/${id}`, version);
}
